.action-button {
  background: transparent;
}

.p-column-filter-buttonbar {
  display: none;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0.2rem;
}

.data-table-header-short {
  width: 5rem;
}

.data-table-header-long {
  width: 13rem;
}

.p-column-filter .p-dropdown-panel {
  max-width: 10rem;
}

.p-column-filter .p-dropdown {
  height: 1.9rem;
}

.p-column-filter .p-dropdown.p-dropdown-clearable .p-dropdown-label {
  margin-top: -2px;
}

<template>
  <div class="wrapper">
    <span class="row-count" style="border-bottom: 1px solid black);">
      Total creators: {{ filteredCreators.length }}
    </span>
    <div id="creators" class="creators">
      <DataTable
        :value="filteredCreators"
        :loading="isLoading"
        :rowHover="true"
        :paginator="true"
        :rows="50"
        :filters.sync="filterConfig"
        filterDisplay="row"
        :totalRecords="filteredCreators.length"
        dataKey="id"
        responsiveLayout="scroll"
      >
        <Column
          v-for="col in columns"
          :key="col.field"
          :bodyClass="col.bodyClass"
          :field="col.field"
          :header="col.header"
          :headerClass="col.headerClass"
          :sortable="col.sortable"
          :showFilterMenu="false"
          :showFilterOperator="false"
          :showFilterMatchModes="false"
          :showAddButton="false"
          :showClearButton="false"
          :showApplyButton="false"
        >
          <template #loading>
            <span class="loading-text"> </span>
          </template>

          <template v-if="col.field === 'residence_country'" #body="slotProps">
            <span class="p-d-flex p-ai-center country-flag">
              <country-flag
                :country="slotProps.data.residence_country || 'null'"
                size="small"
              />
              {{ countryCodeMapping[slotProps.data.residence_country] }}
            </span>
          </template>
          <template
            v-else-if="col.field === 'termination_scheduled'"
            #body="slotProps"
          >
            <i
              v-if="slotProps.data.termination_scheduled"
              class="pi pi-check"
            ></i>
            <i v-else class="pi pi-times"></i>
          </template>

          <template #filter>
            <Dropdown
              v-if="col.field === 'residence_country'"
              v-model="filters[col.field]"
              :class="col.filterClass"
              :filter="true"
              :options="countries"
              appendTo="manage"
              dataKey="name"
              optionLabel="name"
              optionValue="alpha2Code"
              placeholder=""
              showClear
              @input="filterCreators"
            ></Dropdown>
            <Dropdown
              v-else-if="col.field === 'termination_scheduled'"
              v-model="filters['termination_scheduled']"
              :options="yesNoOptions"
              appendTo="manage"
              class="p-filter-dropdown"
              dataKey="name"
              optionLabel="name"
              optionValue="value"
              placeholder=""
              showClear
              @input="filterCreators"
            />
            <InputText
              v-else
              v-model="filters[col.field]"
              :class="col.filterClass"
              placeholder=""
              type="text"
              @input="filterCreators"
            />
          </template>
        </Column>

        <Column
          headerClass="data-table-header data-table-body-action"
          bodyClass="data-table-body data-table-body-action data-table-body-dense"
        >
          <template #loading>
            <span class="loading-text"> </span>
          </template>
          <template #body="slotProps">
            <router-link
              :to="'/manage/creators/' + slotProps.data.id"
              tag="button"
              class="p-m-0 p-p-0 action-button"
            >
              <Button
                class="p-button-rounded p-button-main p-button-sm manage-list-view-button"
                label="View"
              />
            </router-link>
          </template>
        </Column>
      </DataTable>
      <CreatorImport
        :isOpen.sync="showImportModal"
        @import-success="refreshCreatorList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { readCountries, readCreators } from "@/store/main/getters";
import { dispatchListCreators } from "@/store/main/actions";
import CreatorImport from "./CreatorImport.vue";
import "@/assets/styles/datatable.css";

@Component({
  components: {
    CreatorImport,
  },
})
export default class Creators extends Vue {
  public yesNoOptions = [
    {
      name: "Yes",
      value: "true",
    },
    {
      name: "No",
      value: "false",
    },
  ];

  public columns = [
    {
      header: "ID",
      field: "id",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header data-table-header-short",
      filterClass: "p-column-filter p-filter-column p-filter-column-first",
    },
    {
      header: "First Name",
      field: "first_name",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Last Name",
      field: "last_name",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Email",
      field: "email",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Phone Number",
      field: "phone_number",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Country",
      field: "residence_country",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header data-table-header-long",
      filterClass: "p-filter-dropdown",
    },
    {
      header: "Termination Scheduled",
      field: "termination_scheduled",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-filter-dropdown",
    },
  ];

  public filterConfig = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    first_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    last_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    phone_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    residence_country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    termination_scheduled: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  public showImportModal = false;
  public filters = {};
  public filteredCreators = [];
  public isLoading = false;

  public get countries() {
    return readCountries(this.$store);
  }

  public get countryCodeMapping() {
    const mapping = {};
    this.countries.forEach((country) => {
      mapping[country.alpha2Code] = country.name;
    });
    return mapping;
  }

  public get creators() {
    return readCreators(this.$store);
  }

  public filterCreators() {
    let out = this.creators;
    for (const [key, value] of Object.entries(this.filters)) {
      if (key == "residence_country" || key == "termination_scheduled") {
        if (value !== null && value !== 0 && value !== "") {
          out = out.filter(
            (creator) => (creator[key] ?? "").toString() == value
          );
        }
      } else if (value !== null && value !== "") {
        out = out.filter((creator) => {
          if (creator[key] !== null && creator[key] !== "") {
            return (creator[key] ?? "")
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase());
          }
          return false;
        });
      }
    }
    this.filteredCreators = out;
  }

  public async refreshCreatorList() {
    this.isLoading = true;
    await dispatchListCreators(this.$store);
    this.filteredCreators = this.creators;
    this.isLoading = false;
  }

  @Watch("$route.path", { immediate: true })
  public toggleCreatorModal() {
    if (this.$route.path === "/manage/creators/import") {
      this.showImportModal = true;
    }
  }

  public async mounted() {
    window["analytics"]["page"]("/manage/creators");

    this.isLoading = true;
    await dispatchListCreators(this.$store);
    this.filteredCreators = this.creators;
    this.isLoading = false;
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
}

.creators {
  height: calc(100vh - 130px);
}

.loading-text {
  display: block;
  background-color: #f1f1f1;
  min-height: 16px;
  -webkit-animation: pulse 1s ease-in-out infinite;
  animation: pulse 1s ease-in-out infinite;
  text-indent: -99999px;
  overflow: hidden;
}

.country-flag {
  margin-left: -20px;
}
</style>
